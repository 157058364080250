import React from 'react';
import './App.css';
import { Grid } from '@mui/material';
import Logo from './Logo.png';
import Untitled from './Untitled.png';

type TimeLeft = {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
};

const endTime = new Date("2024-08-30T22:00:00.000Z");

function getTimeDifference(): TimeLeft {
  const startTime = new Date().getTime();
  const difference = endTime.getTime() - startTime;
  const differenceInMinutes = difference / 1000 / 60;
  const days = Math.max(0, Math.floor(differenceInMinutes / 60 / 24));
  const hours = Math.max(0, Math.floor(differenceInMinutes / 60) % 24);
  const minutes =  Math.max(0, Math.floor(differenceInMinutes % 60));
  const differenceInSeconds = difference / 1000;
  const seconds = Math.max(0, Math.floor((differenceInSeconds) % 60));

  return {
    days,
    hours,
    minutes: minutes,
    seconds: seconds
  };
};

export default class App extends React.Component<{}, TimeLeft> {
  state = getTimeDifference();
  private interval?: NodeJS.Timer;

  componentDidMount(): void {
    this.interval = setInterval(() => {
      this.setState(getTimeDifference());
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  render() {
    const timeLeft = this.state;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'relative',
            marginBottom: 20,
            flexDirection: 'column'
          }}
        >
          <img src={Logo} width={150} style={{imageRendering: 'crisp-edges', zIndex: 2}}></img>
          <img src={Untitled} width={256} style={{imageRendering: 'pixelated', zIndex: 2, borderRadius: 5, marginTop: 10}}></img>
        </Grid>
        {!(timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) && <Grid
          container
          xs={2}
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: '#181818',
            borderRadius: 100,
            minWidth: "400px",
            zIndex: 10
          }}
        >
          <Grid item xs={12}>
            <p style={{color: '#FEFEFE', fontSize: 27, margin: 0, width: '100%', textAlign: 'center', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              Launching in
            </p>
          </Grid>
          <Grid
            container
            xs={10}
            alignItems="center"
            justifyContent="center"
            style={{marginTop: 10, marginBottom: 20}}
          >
            {timeLeft.days > 0 && <Grid item xs={3}>
              <p style={{color: '#FEFEFE', fontSize: 27, width: '100%', textAlign: 'center', margin: 0}}>{timeLeft.days}</p>
              <p style={{color: '#FEFEFE', fontSize: 12, width: '100%', textAlign: 'center', margin: 0}}>DAYS</p>
            </Grid>}
            {(timeLeft.days > 0 || timeLeft.hours > 0) && <Grid item xs={3}>
              <p style={{color: '#FEFEFE', fontSize: 27, width: '100%', textAlign: 'center', margin: 0}}>{timeLeft.hours}</p>
              <p style={{color: '#FEFEFE', fontSize: 12, width: '100%', textAlign: 'center', margin: 0}}>HOURS</p>
            </Grid>}
            {(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) && <Grid item xs={3}>
              <p style={{color: '#FEFEFE', fontSize: 27, width: '100%', textAlign: 'center', margin: 0}}>{timeLeft.minutes}</p>
              <p style={{color: '#FEFEFE', fontSize: 12, width: '100%', textAlign: 'center', margin: 0}}>MINUTES</p>
            </Grid>}
            {(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0)  && <Grid item xs={3}>
              <p style={{color: '#FEFEFE', fontSize: 27, width: '100%', textAlign: 'center', margin: 0}}>{timeLeft.seconds}</p>
              <p style={{color: '#FEFEFE', fontSize: 12, width: '100%', textAlign: 'center', margin: 0}}>SECONDS</p>
            </Grid>}
          </Grid>
        </Grid>}
        {timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0 && <Grid
          container
          xs={2}
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: '#181818',
            borderRadius: 100,
            minWidth: "400px"
          }}
        >
          <Grid item xs={12}>
            <p style={{color: '#FEFEFE', fontSize: 27, margin: 0, width: '100%', textAlign: 'center', marginTop: 10}}>
              Waiting Launched Event
            </p>
            <p style={{color: '#FEFEFE', fontSize: 17, margin: 0, width: '100%', textAlign: 'center', marginTop: 10, marginBottom: 10}}>
              Some servers are down but feel free to explore
            </p>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <p
              style={{cursor: 'pointer', color: '#FEFEFE', backgroundColor: 'red', fontSize: 27, margin: 0, borderRadius: 1000, width: '200px', textAlign: 'center', marginTop: 10, marginBottom: 20}}
              onClick={() => window.location.href = "https://play.canvaslegacyrewritten.com"}
            >
              Play now
            </p>
          </Grid>
        </Grid>}
      </Grid> 
    );
  }
};

